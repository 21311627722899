<template>
  <span>
    <flixIcon class="icon" :id="'clock'" />
    <a @click.prevent="edit.hour = true; edit.minute = false" v-if="!edit.hour" href="#" class="flix-html-a time">{{ $getNullsBefore(defaultHour) }}</a>
    <select @change="callback({hour: defaultHour, minute: defaultMinute}); edit.hour = false" v-else class="flix-form-control" v-model="defaultHour">
      <option v-for="hour in Array(24).keys()" :key="hour" v-bind:value="hour">{{ $getNullsBefore(hour) }}</option>
    </select>
    :
    <a @click.prevent="edit.hour = false; edit.minute = true" v-if="!edit.minute" href="#" class="flix-html-a time">{{ $getNullsBefore(defaultMinute) }}</a>
    <select @change="callback({hour: defaultHour, minute: defaultMinute}); edit.minute = false" v-else class="flix-form-control" v-model="defaultMinute">
      <option v-for="minute in getMinutes" :key="minute" v-bind:value="minute">{{  $getNullsBefore(minute) }}</option>
      <option v-if="defaultHour == 23" value="59">{{  $getNullsBefore(59) }}</option>
    </select>
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    data: String,
    variant: {
      type: [String, Number],
      default () { return 15 }
    }
  },
  data () {
    return {
      defaultHour: this.data.split(':')[0] * 1,
      defaultMinute: this.data.split(':')[1] * 1,
      edit: {
        hour: false,
        minute: false
      }
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {
    getMinutes () {
      const r = []
      for (let i = 0; i < 60; i += (this.variant * 1)) {
        r.push(i)
      }
      return r
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-form-control
    display: inline-block
    width: inherit
  .time
    text-decoration: underline
    font-size: 16pt
  .icon
    font-size: 16pt
    margin-right: 5pt

</style>
